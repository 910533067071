import { useEffect } from 'react';
import { VirtualTourPlugin } from '@photo-sphere-viewer/virtual-tour-plugin';
import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import { PlanPlugin } from '@photo-sphere-viewer/plan-plugin';

import { detectBearing } from 'utils/immersive-view';

const useDetectNodeChanged = ({
  viewer,
  images,
  nodeMap,
  toggleLock,
  twoPtsAngle,
  setIndexNode,
  viewerOnReady,
  planImageViewer
}) => {
  useEffect(async () => {
    if (viewer && planImageViewer?.miniHeight && viewerOnReady) {
      const markersPlugin = viewer.getPlugin(MarkersPlugin);
      const planPlugin = viewer.getPlugin(PlanPlugin);
      const virtualTourPlugin = viewer.getPlugin(VirtualTourPlugin);

      virtualTourPlugin?.addEventListener('node-changed', (e) => {
        try {
          const { node, data } = e;
          if (!data.fromNode) return;
          const currentNode = nodeMap.get(node.id);
          markersPlugin.clearMarkers();
          currentNode.markers.forEach((marker) => {
            markersPlugin.addMarker(marker);
          });

          planPlugin.setCoordinates([currentNode.positionOnMap.x, currentNode.positionOnMap.y]);
          let bearing = detectBearing({
            images,
            viewer,
            twoPtsAngle,
            currentIndexNode: currentNode.index
          });
          setIndexNode(currentNode.index);
          planPlugin.setOptions({
            bearing: bearing
          });

          if (data.fromLinkPosition) {
            // changed node by arrow button
            toggleLock(false);
          }
        } catch (error) {
          console.log('error', error);
        }
      });
    }
  }, [planImageViewer, viewerOnReady]);
};

export default useDetectNodeChanged;
