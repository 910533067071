import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router';
import { connect } from 'react-redux';
import AuthStorage from 'utils/AuthStorage';
import get from 'lodash/get';

function mapStateToProps(state) {
  let userInfo = get(state, 'user.user.data');

  return {
    store: {
      userInfo
    }
  };
}

class SaasWrap extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  state = {
    path: ''
  };

  componentDidMount() {
    const { history } = this.props;
    if (!AuthStorage.isSuperAdmin && AuthStorage.status === 'unsubscribed') {
      history.push('/dashboard');
    }
    this.setState({ path: history.location.pathname });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.history.location.pathname !== this.state.path &&
      nextProps.history.location.pathname !== '/dashboard' &&
      !AuthStorage.isSuperAdmin
    ) {
      if (AuthStorage.status === 'unsubscribed') {
        this.setState({ path: nextProps.history.location.pathname }, () => {
          nextProps.history.push('/profile');
        });
      }
    }
  }

  render() {
    const { children, store } = this.props;
    if (!AuthStorage.loggedIn) {
      return null;
    }

    if (AuthStorage.status === 'unsubscribed' && !AuthStorage.isSuperAdmin) {
      return <Redirect to="/dashboard" />;
    }
    return children;
  }
}

export default withRouter(connect(mapStateToProps)(SaasWrap));
