import { VirtualTourPlugin } from '@photo-sphere-viewer/virtual-tour-plugin';
import isEmpty from 'lodash/isEmpty';
import { forwardRef, useEffect } from 'react';

import {
  BottomRightWrapper,
  BottomWrapper,
  ControlAddNewDefect,
  ControlCloseSite,
  ControlDate,
  ControlNavigation,
  ControlSplitView
} from '../ControlPanel';
import useAddDefectMode from './hooks/useAddDefect';
import useDetectNodeChanged from './hooks/useDetectNodeChanged';
import useInitialViewer from './hooks/useInitialViewer';
import useStylePointer from './hooks/useStylePointer';

const Viewer = forwardRef(
  (
    {
      images,
      locked,
      index = 0,
      isCompare,
      twoPointAngle,
      availableDates,
      planImageViewer,
      mappedDataViewer,
      initialIndexNode = 0,
      // ---- functions
      onCompare,
      offCompare,
      toggleLock,
      getContainer,
      onChangeDate,
      handleSetLockPosition,
      setDisplayDefectDetail
    },
    ref
  ) => {
    const viewer = ref.current;
    const containerRef = getContainer();
    const isDisplayCloseButton = index === 1;
    const isDisplayController = index === 0 || isCompare;

    const { onReadyViewer, currentDate, currentIndexNode, setCurrentDate, setCurrentIndexNode } =
      useInitialViewer({
        toggleLock,
        viewerRef: ref,
        images: images,
        setDisplayDefectDetail,
        twoPtsAngle: twoPointAngle,
        containerRef: containerRef,
        mappedData: mappedDataViewer,
        planImageViewer: planImageViewer,
        initialIndexNode: initialIndexNode,
        initialDate: availableDates[0].date,
        enabled: index === 0 || isCompare ? true : false
      });

    useDetectNodeChanged({
      toggleLock,
      images: images || [],
      viewer: viewer,
      twoPtsAngle: twoPointAngle,
      viewerOnReady: onReadyViewer,
      planImageViewer: planImageViewer,
      nodeMap: mappedDataViewer.nodeMap,
      setIndexNode: setCurrentIndexNode
    });

    const { isAddMode, toggleAddMode, createDefectModal, handleInjectCanvas, handleRemoveCanvas } =
      useAddDefectMode({
        viewer,
        images: images,
        planImage: planImageViewer,
        currentIndexNode: currentIndexNode,
        hotspots: mappedDataViewer.hotspots,
        onSubmit: handleCreateNewDefect
      });

    useStylePointer({
      viewer,
      isAddMode: isAddMode
    });

    const handleNextScene = ({ viewer, images, currentIndex }) => {
      if (!viewer) return;
      const virtualTourPlugin = viewer.getPlugin(VirtualTourPlugin);
      const nextIndex = currentIndex + 1;
      if (nextIndex < images.length) {
        virtualTourPlugin.setCurrentNode(`node-${nextIndex}`);
        toggleLock(false);
      }
    };

    const handleBackScene = ({ viewer, currentIndex }) => {
      if (!viewer) return;
      const virtualTourPlugin = viewer.getPlugin(VirtualTourPlugin);
      const nextIndex = currentIndex - 1;
      if (nextIndex >= 0) {
        virtualTourPlugin.setCurrentNode(`node-${nextIndex}`);
        toggleLock(false);
      }
    };

    const handleOnChangeDate = (date) => {
      const missionGuid = availableDates.find((x) => x.date === date)?.mission_guid;
      if (missionGuid) {
        onChangeDate(missionGuid, index);
        toggleLock(false);
        setCurrentDate(date);
        setCurrentIndexNode(0);
      }
    };

    const handleToggleAddMode = () => {
      toggleAddMode();
      try {
        if (isAddMode) {
          toggleAddMode(false);
          handleRemoveCanvas();
          handleSetLockPosition(null, index);
        } else {
          const currentPosition = viewer.getPosition();
          handleSetLockPosition(currentPosition, index);
          toggleAddMode(true);
          handleInjectCanvas();
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    const forgeOffAddMode = () => {
      toggleAddMode(false);
      handleRemoveCanvas();
      handleSetLockPosition(null, index);
    };

    function handleCreateNewDefect(values) {
      console.log('values', values);
    }

    useEffect(() => {
      forgeOffAddMode();
    }, [locked]);

    if (isEmpty(images)) return null;
    return (
      <>
        {createDefectModal()}

        {isDisplayCloseButton && (
          <ControlCloseSite
            isCompare={isCompare}
            turnOnCompare={onCompare}
            turnOffCompare={offCompare}
          />
        )}

        {isDisplayController && (
          <>
            <BottomWrapper>
              <ControlNavigation
                disabledBack={currentIndexNode === 0}
                disabledNext={currentIndexNode + 1 >= images.length}
                onNext={() =>
                  handleNextScene({
                    viewer,
                    images: images,
                    currentIndex: currentIndexNode
                  })
                }
                onBack={() =>
                  handleBackScene({
                    viewer,
                    currentIndex: currentIndexNode
                  })
                }
                centerElement={
                  <ControlDate
                    id="control-date-1"
                    value={currentDate}
                    availableDates={availableDates.map(({ date }) => date)}
                    onChange={(value) => handleOnChangeDate(value)}
                  />
                }
              />
            </BottomWrapper>

            <BottomRightWrapper>
              <ControlSplitView
                isCompare={isCompare}
                turnOnCompare={() => {
                  onCompare();
                  isAddMode && handleToggleAddMode();
                }}
              />
              <ControlAddNewDefect isAddMode={isAddMode} toggleAddMode={handleToggleAddMode} />
            </BottomRightWrapper>
          </>
        )}
      </>
    );
  }
);

Viewer.displayName = 'Viewer';
export default Viewer;
