import classNames from 'classnames';
import { forwardRef, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

const DatePicker = forwardRef(
  (
    {
      id,
      label,
      name,
      onChange,
      onBlur,
      value,
      min,
      max,
      errorMessage,
      disabled = false,
      wrapperClassName,
      availableDates
    },
    ref
  ) => {
    const flatpickrRef = useRef(null);

    return (
      <div className={classNames('form-group', wrapperClassName)}>
        {label && (
          <label className="mr-4" htmlFor={id}>
            {label}
          </label>
        )}

        <Flatpickr
          name={name}
          value={value}
          onBlur={onBlur}
          readOnly={false}
          disabled={disabled}
          ref={flatpickrRef}
          options={{
            minDate: min,
            maxDate: max,
            enable: availableDates?.map((date) => new Date(date)) || []
          }}
          onChange={([date]) => onChange(moment(date).format('YYYY-MM-DD'))}
          render={({ defaultValue, value, ...props }, ref) => {
            return (
              <div className="d-flex align-items-center form-control">
                <input
                  {...props}
                  ref={ref}
                  type="date"
                  defaultValue={defaultValue}
                  className="border-0 no-focus cursor-pointer"
                />
                <i
                  aria-hidden="true"
                  className="fa fa-calendar ml-2 cursor-pointer"
                  onClick={(e) => flatpickrRef.current.flatpickr.open()}></i>
              </div>
            );
          }}></Flatpickr>

        {errorMessage && <Feedback message={errorMessage} />}
      </div>
    );
  }
);

DatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  wrapperClassName: PropTypes.string
};
export default DatePicker;
