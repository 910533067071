import * as yup from 'yup';
import { stores } from '../index';

export const Validator = {
  isNumber(str) {
    const regex = /^[0-9]+$/g;
    return regex.test(str);
  }
};

export const validatorCreateGroup = (values) => {
  let error = {};
  if (!values) return;
  if (values.groupName.trim().length >= 256) {
    error.groupName = 'Group name exceeds 256 characters';
  }
  return error;
};

export const getReportValidationMessages = () => {
  const state = stores.getState();
  const resetPasswordPage = state.contentPage.languageContent.data.data.resetPasswordPage;

  return {
    atLeast12: resetPasswordPage.message.requireValidateMessage.require12MinimumCharacters,
    atLeast8: resetPasswordPage.message.requireValidateMessage.requireLeast8Alphanumeric,
    followingRules: resetPasswordPage.message.requireValidateMessage.requireCategories
  };
};

export const patternRulesPassword =
  /^(?=.*[a-z][A-Z\d\W_]|[A-Z][a-z\d\W_]|[\d][A-Za-z\W_]|[\W_][A-Za-z\d])[A-Za-z\d\W_]{1,}$/;

export const ReportValidationPassword = {
  validatorPassword: yup
    .string()
    .test('atLeast8', 'atLeast8', function (value) {
      if (value.length >= 8) {
        return true;
      }
      return false;
    })
    .test('atLeast12', 'atLeast12', function (value) {
      if (value.length >= 12) {
        return true;
      }
      return false;
    })
    .test('followingRules', 'followingRules', function (value) {
      if (value.match(patternRulesPassword)) {
        return true;
      }
      return false;
    }),
  report(password) {
    const reportValidationMessages = getReportValidationMessages();
    try {
      this.validatorPassword.validateSync(password, {
        abortEarly: false
      });
      return (
        <ul className="pl-4">
          {Object.keys(reportValidationMessages).map((key) => (
            <li key={key}>
              <span style={{ textTransform: 'none' }}>
                {reportValidationMessages[key]} <i className="fa fa-check" aria-hidden="true"></i>
              </span>
            </li>
          ))}
        </ul>
      );
    } catch (error) {
      if (error.name === 'ValidationError') {
        return (
          <ul className="pl-4">
            {Object.keys(reportValidationMessages).map((key) => {
              const isPassed = error.errors.every((errorKey) => errorKey !== key);
              return (
                <li key={key}>
                  <span style={{ textTransform: 'none' }}>
                    {reportValidationMessages[key]}{' '}
                    {isPassed && <i className="fa fa-check" aria-hidden="true"></i>}
                  </span>
                </li>
              );
            })}
          </ul>
        );
      }
      return (
        <ul className="pl-4">
          {Object.keys(reportValidationMessages).map((key) => (
            <li key={key}>
              <span style={{ textTransform: 'none' }}>{reportValidationMessages[key]}</span>
            </li>
          ))}
        </ul>
      );
    }
  }
};
