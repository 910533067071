import { Viewer } from '@photo-sphere-viewer/core';
import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import { PlanPlugin } from '@photo-sphere-viewer/plan-plugin';
import { VirtualTourPlugin } from '@photo-sphere-viewer/virtual-tour-plugin';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { detectBearing, getPlugins } from 'utils/immersive-view';

const MAP_SIZE = {
  maximized: 'maximized',
  normal: 'normal'
};

const useInitialViewer = ({
  images,
  enabled,
  initialDate,
  viewerRef,
  toggleLock,
  mappedData,
  twoPtsAngle,
  containerRef,
  planImageViewer,
  initialIndexNode = 0,
  setDisplayDefectDetail
}) => {
  const [currentDate, setCurrentDate] = useState(initialDate);
  const [onReadyViewer, setOnReadyViewer] = useState(false);
  const [currentIndexNode, setCurrentIndexNode] = useState(0);
  const { immersive360View } = useSelector(
    (state) => state.contentPage.languageContent.data.data.missionDetailPage
  );

  const firstLoadViewer = useCallback(
    ({ viewer, images, nodeMap, currentIndexNode, nodes, hotspots }) => {
      const markersPlugin = viewer.getPlugin(MarkersPlugin);
      const planPlugin = viewer.getPlugin(PlanPlugin);
      const virtualTourPlugin = viewer.getPlugin(VirtualTourPlugin);

      const currentNodeId = `node-${currentIndexNode}`;
      const currentNode = nodeMap.get(currentNodeId);
      const bearing = detectBearing({
        viewer,
        images,
        twoPtsAngle,
        currentIndexNode
      });

      viewer.zoom(0);
      planPlugin.setOptions({
        bearing: bearing
      });
      virtualTourPlugin.setNodes(nodes, [currentNodeId]);
      currentNode.markers.forEach((marker) => {
        markersPlugin.addMarker(marker);
      });
      planPlugin?.setHotspots(hotspots); // TODO: improve performance plan
      planPlugin?.setCoordinates([currentNode.positionOnMap.x, currentNode.positionOnMap.y]);
    },
    [twoPtsAngle, enabled]
  );

  const initialEvents = async ({ viewer, hotspotsMap }) => {
    const markersPlugin = viewer.getPlugin(MarkersPlugin);
    const planPlugin = viewer.getPlugin(PlanPlugin);
    const virtualTourPlugin = viewer.getPlugin(VirtualTourPlugin);
    const map = planPlugin.getLeaflet();

    // events
    planPlugin?.addEventListener('select-hotspot', ({ hotspotId }) => {
      try {
        const selectedHotspot = hotspotsMap.get(hotspotId);
        virtualTourPlugin.setCurrentNode(selectedHotspot.nodeId);
        toggleLock(false);
        if (!selectedHotspot.data) {
          console.log('Select image hotspot');
        } else {
          viewer.rotate({
            pitch: selectedHotspot.data.pitch + 'deg',
            yaw: selectedHotspot.data.yaw + 'deg'
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    });

    planPlugin?.addEventListener('view-changed', ({ view }) => {
      switch (view) {
        case MAP_SIZE.maximized:
          map.setZoom(1);
          break;
        case MAP_SIZE.normal:
        default:
          map.setZoom(0);
      }
    });

    markersPlugin.addEventListener('select-marker', ({ marker }) => {
      const defect = marker.config.data || {};
      if (!defect) return;
      setDisplayDefectDetail({
        open: true,
        defect: defect
      });
    });
  };

  useEffect(() => {
    if (!enabled) return;
    if (containerRef.current) {
      setOnReadyViewer(false);
      try {
        const { hotspotsMap, nodeMap, nodes, hotspots } = mappedData;
        const viewer = new Viewer({
          container: containerRef.current,
          plugins: getPlugins(planImageViewer),
          zoomSpeed: 50,
          navbar: ['zoom'],
          loadingTxt: immersive360View.loadingScene
        });

        firstLoadViewer({
          nodes,
          viewer,
          nodeMap,
          hotspots,
          images,
          currentIndexNode: initialIndexNode || currentIndexNode
        });
        initialEvents({ viewer, hotspotsMap });

        viewerRef.current = viewer;
        setTimeout(() => {
          setOnReadyViewer(true);
        }, 200);
      } catch (error) {
        setOnReadyViewer(false);
        false;
        console.log('error', error);
      }
    }

    return () => {
      if (viewerRef.current) {
        setOnReadyViewer(false);
        console.log('Viewer is destroyed');
        viewerRef.current.destroy();
      }
    };
  }, [images, enabled]);

  return {
    onReadyViewer,
    currentIndexNode,
    currentDate,
    setOnReadyViewer,
    setCurrentIndexNode,
    setCurrentDate
  };
};

export default useInitialViewer;
