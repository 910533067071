import DatePicker from 'components/common/DatePicker';
import PropTypes from 'prop-types';

export const ControlDate = ({ value, onChange, availableDates, id }) => {
  return (
    <DatePicker
      id={id}
      value={value}
      onChange={onChange}
      wrapperClassName="m-0"
      availableDates={availableDates}
    />
  );
};

ControlDate.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
