export * from './ControlAddNewDefect';
export * from './ControlCloseSite';
export * from './ControlLockView';
export * from './ControlNavigation';
export * from './ControlSelectDate';
export * from './ControlSplitView';

export const BottomWrapper = ({ children }) => {
  return (
    <div
      className="position-absolute d-flex"
      style={{
        gap: 8,
        bottom: 1,
        left: '50%',
        zIndex: 999,
        borderRadius: 8,
        transform: 'translate(-50%, 0%)'
      }}>
      {children}
    </div>
  );
};

export const BottomRightWrapper = ({ children }) => {
  return (
    <div
      className="position-absolute"
      style={{
        right: 8,
        bottom: 8,
        padding: 8,
        zIndex: 999,
        borderRadius: 8,
        backgroundColor: 'white'
      }}>
      {children}
    </div>
  );
};
