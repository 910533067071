import { VirtualTourPlugin } from '@photo-sphere-viewer/virtual-tour-plugin';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { generateRequestId } from 'utils/FetchApi';
import { appendRequestId, removeRequestId } from 'redux/action/loadingModal';

const useSyncView = ({
  viewer1,
  viewer2,
  nodesViewer2,
  lockedPositionViewer1,
  lockedPositionViewer2
}) => {
  const dispatch = useDispatch();
  const [locked, setLock] = useState(false);

  const toggleLock = useCallback((nextValue) => {
    if (typeof nextValue !== 'undefined') return setLock(nextValue);
    setLock((prev) => !prev);
  }, []);

  const syncViewers = (sourceViewer, targetViewer, locked) => {
    const indexViewer = sourceViewer.parent.getAttribute('data-index');
    if (indexViewer == 0 && !isEmpty(lockedPositionViewer1)) {
      sourceViewer.rotate({
        pitch: lockedPositionViewer1.pitch,
        yaw: lockedPositionViewer1.yaw
      });
      return;
    }

    if (indexViewer == 1 && !isEmpty(lockedPositionViewer2)) {
      sourceViewer.rotate({
        pitch: lockedPositionViewer2.pitch,
        yaw: lockedPositionViewer2.yaw
      });
      return;
    }
    if (!locked || !sourceViewer || !targetViewer) return;
    try {
      targetViewer.rotate({
        pitch: sourceViewer.getPosition().pitch,
        yaw: sourceViewer.getPosition().yaw
      });
      targetViewer.zoom(sourceViewer.getZoomLevel());
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSyncIndex = async () => {
    const requestId = generateRequestId();
    try {
      if (!locked) return;
      dispatch(appendRequestId(requestId));
      const virtualTourPluginViewer1 = viewer1.getPlugin(VirtualTourPlugin);
      const virtualTourPluginViewer2 = viewer2.getPlugin(VirtualTourPlugin);
      const currentNode = virtualTourPluginViewer1?.getCurrentNode(); // current node viewer1

      const found = nodesViewer2.find((node) => {
        return (
          node.xyz[0] === currentNode.xyz[0] &&
          node.xyz[1] === currentNode.xyz[1] &&
          node.xyz[2] === currentNode.xyz[2]
        );
      });
      if (found) {
        virtualTourPluginViewer2.setCurrentNode(found.id);
        dispatch(removeRequestId(requestId));
        toggleLock(true);
      } else {
        dispatch(removeRequestId(requestId));
        toggleLock(false);
      }
    } catch (error) {
      console.log('error handleSyncIndex', error);
    } finally {
      dispatch(removeRequestId(requestId));
    }
  };

  useEffect(() => {
    handleSyncIndex();
    const triggerSyncViewer1 = () => {
      syncViewers(viewer1, viewer2, locked);
    };
    const triggerSyncViewer2 = () => {
      syncViewers(viewer2, viewer1, locked);
    };

    if (viewer1) {
      viewer1.addEventListener('position-updated', triggerSyncViewer1);
      viewer1.addEventListener('zoom-updated', triggerSyncViewer1);
    }
    if (viewer2) {
      viewer2.addEventListener('position-updated', triggerSyncViewer2);
      viewer2.addEventListener('zoom-updated', triggerSyncViewer2);
    }

    return () => {
      if (viewer1) {
        viewer1.removeEventListener('position-updated', triggerSyncViewer1);
        viewer1.removeEventListener('zoom-updated', triggerSyncViewer1);
      }

      if (viewer2) {
        viewer2.removeEventListener('position-updated', triggerSyncViewer2);
        viewer2.removeEventListener('zoom-updated', triggerSyncViewer2);
      }
    };
  }, [locked, lockedPositionViewer1, lockedPositionViewer2]);

  return { locked, toggleLock };
};

export default useSyncView;
