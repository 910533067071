export const ControlSplitView = ({ isCompare, turnOnCompare }) => {
  return (
    <div>
      {!isCompare && (
        <div
          title="Split view"
          data-placement="left"
          data-toggle="tooltip"
          onClick={turnOnCompare}
          className="cursor-pointer">
          <i className="fa fa-columns" aria-hidden="true" style={{ fontSize: 24 }}></i>
        </div>
      )}
    </div>
  );
};
