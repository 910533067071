import classNames from 'classnames';

export const ControlAddNewDefect = ({ toggleAddMode, isAddMode }) => {
  return (
    <div
      title="Allow add new defect"
      data-placement="left"
      data-toggle="tooltip"
      onClick={() => toggleAddMode()}
      className={classNames('cursor-pointer', {
        'text-primary': isAddMode
      })}>
      <i className="fa fa-plus-square-o" aria-hidden="true" style={{ fontSize: 28 }}></i>
    </div>
  );
};
