import React, { useEffect } from 'react';
import useRouter from 'hooks/useRouter';
import useToast from 'hooks/useToast';
import { Autodesk } from 'utils/Autodesk';
import AutodeskFetcher from 'services/autodesk/fetcher';

const AutodeskTokenCatcher = () => {
  const router = useRouter();
  const toaster = useToast();

  const handleGetAutodeskToken = async () => {
    try {
      const { access_token, expires_in, token_type } = await Autodesk.getAutodeskToken(
        router.query.code
      );

      if (access_token && expires_in && token_type) {
        Autodesk.setToken(access_token, new Date(Date.now() + expires_in * 1000));
        AutodeskFetcher.updateToken();
      } else {
        toaster.notifyError(
          'Something went wrong with Autodesk Authorize. Please try again later!'
        );
        Autodesk.clearToken();
      }
    } catch (error) {
      toaster.notifyError('Something went wrong with Autodesk Authorize. Please try again later!');
      Autodesk.clearToken();
    } finally {
      router.replace(Autodesk.getBeforeAuthPage() || '/', {
        from: 'autodesk'
      });
    }
  };
  useEffect(() => {
    handleGetAutodeskToken();
  }, []);

  return <></>;
};

export default AutodeskTokenCatcher;
