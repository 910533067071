import axios from 'axios';
import env from 'config/env';
import CookieUtil from 'utils/Cookie';

const {
  REACT_APP_AUTODESK_CLIENT_ID,
  REACT_APP_AUTODESK_COOKIE_KEY,
  REACT_APP_AUTODESK_REDIRECT_URL,
  REACT_APP_AUTODESK_CLIENT_SECRET
} = env;

const beforeAuthPageKey = 'before-auth-page';

export const Autodesk = {
  setToken(token, expires) {
    CookieUtil.setCookie(REACT_APP_AUTODESK_COOKIE_KEY, token, expires);
  },
  getToken(prefix) {
    const token = CookieUtil.getCookie(REACT_APP_AUTODESK_COOKIE_KEY);
    if (!token) return '';
    return prefix ? `${prefix} ${token}` : token;
  },
  clearToken() {
    CookieUtil.removeCookie(REACT_APP_AUTODESK_COOKIE_KEY);
  },
  getAutodeskToken: async (code) => {
    if (!code) throw new Error('Invalid code');

    const payload = {
      code: code,
      grant_type: 'authorization_code',
      redirect_uri: REACT_APP_AUTODESK_REDIRECT_URL
    };

    var formBody = [];
    for (var property in payload) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(payload[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');

    const authString = `${REACT_APP_AUTODESK_CLIENT_ID}:${REACT_APP_AUTODESK_CLIENT_SECRET}`;
    const base64Encoded = btoa(authString);

    const response = await axios.post(
      'https://developer.api.autodesk.com/authentication/v2/token',
      formBody,
      {
        headers: {
          Authorization: `Basic ${base64Encoded}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );

    return {
      access_token: response.data.access_token,
      expires_in: response.data.expires_in,
      token_type: response.data.token_type
    };
  },
  generateAuthUrl() {
    return `https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code&client_id=${REACT_APP_AUTODESK_CLIENT_ID}&redirect_uri=${REACT_APP_AUTODESK_REDIRECT_URL}&scope=data:read`;
  },
  setBeforeAuthPage(fullPathname) {
    localStorage.setItem(beforeAuthPageKey, fullPathname);
  },
  getBeforeAuthPage(defaultPage = '/') {
    const path = localStorage.getItem(beforeAuthPageKey);
    localStorage.removeItem(beforeAuthPageKey);
    return path || defaultPage;
  }
};
