import { VirtualTourPlugin } from '@photo-sphere-viewer/virtual-tour-plugin';
import { useRef, useState } from 'react';

import { DEFAULT_DISPLAY_DEFECT_DETAIL, RATIO } from 'constants/immersive-view';
import { useBoolean } from 'hooks/useBoolean';
import { detectInformation } from 'utils/immersive-view';
import { ControlLockView } from '../ControlPanel';
import DefectDetailModal from '../DefectDetailModal';
import useSyncView from './hooks/useSyncView';
import Viewer from './Viewer';

const SyncView = ({ data, availableDates, onChangeDate }) => {
  const dataViewer1 = data[0];
  const dataViewer2 = data[1];

  const { images: viewer1Images, twoPts_angle: twoPointAngle1 } = dataViewer1 || {};
  const { images: viewer2Images, twoPts_angle: twoPointAngle2 } = dataViewer2 || {};

  const planImageViewer1 = {
    imageSource: dataViewer1?.floorPlanImage || '',
    width: dataViewer1?.floorPlanImageWidth || '',
    height: dataViewer1?.floorPlanImageHeight || '',
    miniWidth: dataViewer1?.floorPlanImageWidth * RATIO || '',
    miniHeight: dataViewer1?.floorPlanImageHeight * RATIO || ''
  };
  const planImageViewer2 = {
    imageSource: dataViewer2?.floorPlanImage || '',
    width: dataViewer2?.floorPlanImageWidth || '',
    height: dataViewer2?.floorPlanImageHeight || '',
    miniWidth: dataViewer2?.floorPlanImageWidth * RATIO || '',
    miniHeight: dataViewer2?.floorPlanImageHeight * RATIO || ''
  };

  const mappedDataViewer1 = detectInformation(viewer1Images, planImageViewer1.miniHeight);
  const mappedDataViewer2 = detectInformation(viewer2Images, planImageViewer2.miniHeight);

  const viewer1Ref = useRef(null);
  const viewer2Ref = useRef(null);
  const container1Ref = useRef(null);
  const container2Ref = useRef(null);
  const [isCompare, onCompare, offCompare] = useBoolean(false);
  const [displayDefectDetail, setDisplayDefectDetail] = useState(DEFAULT_DISPLAY_DEFECT_DETAIL);

  const [lockedPositionViewer1, setLockedPositionViewer1] = useState(null);
  const [lockedPositionViewer2, setLockedPositionViewer2] = useState(null);

  const { locked, toggleLock } = useSyncView({
    viewer2Images,
    lockedPositionViewer1,
    lockedPositionViewer2,
    viewer1: viewer1Ref.current,
    viewer2: viewer2Ref.current,
    nodesViewer2: mappedDataViewer2.nodes
  });

  const initialIndexNodeViewer2 = isCompare ? getCurrentIndexNodeViewer1() : 0;

  function getCurrentIndexNodeViewer1() {
    try {
      const virtualTourPlugin = viewer1Ref.current.getPlugin(VirtualTourPlugin);
      const currentNode = virtualTourPlugin.getCurrentNode();

      return currentNode.index;
    } catch (error) {
      console.log(error);
    }
  }

  const handleSetLockPosition = (value, index) => {
    if (index === 0) {
      setLockedPositionViewer1(value);
    } else {
      setLockedPositionViewer2(value);
    }
  };

  return (
    <div>
      <div className="d-flex mt-3">
        <DefectDetailModal
          isOpen={displayDefectDetail.open}
          defect={displayDefectDetail.defect}
          onClose={() => setDisplayDefectDetail(DEFAULT_DISPLAY_DEFECT_DETAIL)}
        />

        <div
          ref={container1Ref}
          data-index={0}
          style={{
            position: 'relative',
            width: isCompare ? '50%' : '100%',
            height: '80vh',
            objectFit: 'contain'
          }}>
          {dataViewer1 && (
            <Viewer
              index={0}
              locked={locked}
              ref={viewer1Ref}
              isCompare={isCompare}
              images={viewer1Images}
              twoPointAngle={twoPointAngle1}
              availableDates={availableDates}
              planImageViewer={planImageViewer1}
              mappedDataViewer={mappedDataViewer1}
              // ---------------
              onCompare={onCompare}
              offCompare={offCompare}
              toggleLock={toggleLock}
              getContainer={() => {
                return container1Ref;
              }}
              onChangeDate={onChangeDate}
              handleSetLockPosition={handleSetLockPosition}
              setDisplayDefectDetail={setDisplayDefectDetail}
            />
          )}
        </div>

        <ControlLockView
          isCompare={isCompare}
          isLocked={locked}
          toggleLock={() => {
            toggleLock();
            console.log({ e: viewer1Ref.current });
          }}
        />

        <div
          ref={container2Ref}
          data-index={1}
          style={{
            width: isCompare ? '50%' : '0%',
            height: '80vh',
            objectFit: 'contain',
            position: 'relative'
          }}>
          {dataViewer2 && (
            <Viewer
              index={1}
              locked={locked}
              ref={viewer2Ref}
              isCompare={isCompare}
              images={viewer2Images}
              twoPointAngle={twoPointAngle2}
              availableDates={availableDates}
              planImageViewer={planImageViewer2}
              mappedDataViewer={mappedDataViewer2}
              initialIndexNode={initialIndexNodeViewer2}
              // ---------------
              onCompare={onCompare}
              offCompare={offCompare}
              toggleLock={toggleLock}
              getContainer={() => {
                return container2Ref;
              }}
              onChangeDate={onChangeDate}
              handleSetLockPosition={handleSetLockPosition}
              setDisplayDefectDetail={setDisplayDefectDetail}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SyncView;
