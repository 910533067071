import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { Loading } from 'components/common/Loading';
import { useBoolean } from 'hooks/useBoolean';
import { AppContext } from 'layout/AppContext';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest } from 'redux/action/auth';
import { changePassword, forgeChangePassword } from 'redux/action/user';
import {
  getReportValidationMessages,
  patternRulesPassword,
  ReportValidationPassword
} from 'utils/Validator';
import * as yup from 'yup';

const ForgeChangePassword = () => {
  const { appContext } = useContext(AppContext);
  const dispatch = useDispatch();
  const [loading, onLoading, offLoading] = useBoolean(false);
  const contentPage = useSelector((state) => state.languageContent.languageContent.data);
  const {
    popupChangePassword,
    profilePage: { changPasswordPage }
  } = contentPage.data;
  const { firstLogin, passwordExpired, config, cacheOldPassword } = appContext.state;
  const isDisplayModalForgeChangePassword = firstLogin || passwordExpired;

  const validatorForgeChangePassword = yup.object().shape({
    new_password1: yup
      .string()
      .required(changPasswordPage.message.requiredField)
      .test('errReusePassword', changPasswordPage.message.errConfirmPassword, function (value) {
        if (!cacheOldPassword) return true;
        if (cacheOldPassword === value) return false;
        return true;
      })
      .test('atLeast8', getReportValidationMessages().atLeast8, function (value) {
        if (value.length >= 8) {
          return true;
        }
        return false;
      })
      .test('atLeast12', getReportValidationMessages().atLeast12, function (value) {
        if (value.length >= 12) {
          return true;
        }
        return false;
      })
      .test('followingRules', getReportValidationMessages().followingRules, function (value) {
        if (value.match(patternRulesPassword)) {
          return true;
        }
        return false;
      }),
    new_password2: yup
      .string()
      .required(changPasswordPage.message.requiredField)
      .test('errConfirmPassword', changPasswordPage.message.errConfirmPassword, function () {
        const { new_password1, new_password2 } = this.parent;
        if (new_password1 !== new_password2) return false;
        return true;
      })
  });

  const {
    watch,
    reset,
    register,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(validatorForgeChangePassword)
  });
  const newPassword1 = watch('new_password1');

  const forgeLogout = () => {
    dispatch(logoutRequest());
    const name = 'CONFIG';
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };

  const handleOnSubmit = (formData) => {
    onLoading();
    if (passwordExpired) {
      dispatch(
        forgeChangePassword(
          formData,
          (response) => {
            if (response.status) {
              reset();
              appContext.notifySuccess('Notify', changPasswordPage.message.msgSuccessChange);
              appContext.handleSetPasswordExpired(false);
              forgeLogout();
            } else {
              setError('new_password1', {
                message: response.message
              });
            }
            offLoading();
          },
          () => {
            offLoading();
          }
        )
      );
    }
    if (firstLogin) {
      dispatch(
        changePassword(
          {
            old_password: cacheOldPassword,
            ...formData
          },
          (isChangePassword) => {
            if (isChangePassword.detail) {
              reset();
              appContext.notifySuccess('Notify', changPasswordPage.message.msgSuccessChange);
              appContext.handleSetOldPassword('');
              appContext.handleSetFirstLogin(false);
            }
            if (isChangePassword.old_password) {
              setError('old_password', {
                message: isChangePassword.old_password[0]
              });
            }
            if (isChangePassword.new_password2) {
              if (
                isChangePassword.new_password2[0] &&
                isChangePassword.new_password2[0] ===
                  'You can not use a password that is already used in this application.'
              ) {
                setError('new_password1', {
                  message: changPasswordPage.message.errReusePassword
                });
              } else {
                setError('new_password2', {
                  message: isChangePassword.new_password2[0]
                });
              }
            }
            offLoading();
          },
          () => {
            offLoading();
          }
        )
      );
    }
  };

  return (
    <div
      className={`modal bd-example-modal-lg fade ${
        isDisplayModalForgeChangePassword ? 'show' : ''
      }`}>
      <div className="modal-dialog modal-lg popup-assign" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{popupChangePassword.titlePopup}</h5>
            {!passwordExpired && (
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  appContext.handleSetFirstLogin(false);
                }}>
                <i className="fa fa-times"></i>
              </button>
            )}
          </div>
          <Loading loading={loading}>
            <div className="modal-body" style={{ height: 'unset' }}>
              <div className="alert alert-primary" role="alert">
                (*) {popupChangePassword.subtitlePopup}
              </div>
              <form className="form_sign_in" onSubmit={handleSubmit(handleOnSubmit)}>
                <div className="text-center"></div>
                <div className="form-group">
                  <h5>{changPasswordPage.labelNewPassword}: </h5>
                  <input
                    type="password"
                    id="new_password1"
                    {...register('new_password1')}
                    placeholder={changPasswordPage.inputNewPassword}
                    className={classNames('form-control', {
                      error: errors.new_password1
                    })}
                  />
                  {errors.new_password1 && (
                    <label id="id_password-error" className="error" htmlFor="new_password1">
                      <span>{errors.new_password1.message}</span>
                    </label>
                  )}
                  {ReportValidationPassword.report(newPassword1)}
                </div>
                <div className="form-group">
                  <h5>{changPasswordPage.labelConfirmPassword}: </h5>
                  <input
                    type="password"
                    id="new_password2"
                    {...register('new_password2')}
                    placeholder={changPasswordPage.inputConfirmPassword}
                    className={classNames('form-control', {
                      error: errors.new_password2
                    })}
                  />
                  {errors.new_password2 && (
                    <label id="id_password-error" className="error" htmlFor="new_password2">
                      <span>{errors.new_password2.message}</span>
                    </label>
                  )}
                </div>
                <div className="form-group text-center">
                  <button className={`btn-root ${config.btnRoot}`} type="submit">
                    {changPasswordPage.btnUpdate}
                  </button>
                </div>
              </form>
            </div>
            {!passwordExpired && (
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => appContext.handleSetFirstLogin(false)}>
                  {popupChangePassword.textSkip}
                </button>
              </div>
            )}
          </Loading>
        </div>
      </div>
    </div>
  );
};

export default ForgeChangePassword;
