import React from 'react';
import LoadingModal from 'components/LoadingModal';
import './style.scss';

const LoadingFullScreen = () => {
  return (
    <div className="loading-full-screen full-fixed">
      <LoadingModal loading={true} />
    </div>
  );
};

export default LoadingFullScreen;
