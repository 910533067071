import React, { Component } from 'react';
//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { getLanguageContent, getLanguage, getConfig } from 'redux/action/contentPage';
import { checkExpiredPassword } from 'redux/action/user';
import LangStorage from 'utils/LangStorage';
import { isUndefined, isEmpty } from 'lodash';
import RootRoute from 'routes';
import Navbar from 'components/Layout/Header/NavBar/index.js';
import Footer from 'components/Layout/Footer';
import ConfigStorage from 'utils/ConfigStorage';
import { css } from 'emotion';
import { AppContext } from 'layout/AppContext';
import { ToastContainer } from 'react-toastr';
import AuthStorage from 'utils/AuthStorage';
import { defaultConfig } from 'utils/Constants';
import ModalChangePassword from 'components/Modal/ModalChangePassword/v2';
import ReactGA from 'react-ga';
import { ToastContainer as ToastContainerToastify, toast } from 'react-toastify';
import LoadingModal from 'components/LoadingModal';
import LoadingFullScreen from 'components/common/LoadingFullScreen';

function mapStateToProps(state) {
  return {
    store: {
      loadingLanguageContent: state.languageContent.languageContent.loading,
      loadingLanguage: state.languageContent.languageList.loading,
      language: state.languageContent.languageList.data,
      languageContent: state.languageContent.languageContent.data,
      loadingModal: state.loadingModal
    }
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        checkExpiredPassword,
        getLanguageContent,
        getLanguage,
        getConfig
      },
      dispatch
    )
  };
};
let toastr;
class Wrapper extends Component {
  state = {
    config: {},
    isLoading: true,
    firstLogin: false,
    passwordExpired: false,
    cacheOldPassword: ''
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.handleGetLanguage();
    this.handleCheckPasswordExpired();
    const configInCookie = ConfigStorage.getConfig;
    // Check config in cookie
    if (configInCookie && defaultConfig.v !== configInCookie.v) {
      this.handleProcessConfig(configInCookie);
    } else {
      this.handleProcessConfig(defaultConfig);
      ConfigStorage.value = { config: defaultConfig };
    }
    const { history } = this.props;
    history.listen((location) => {
      ReactGA.pageview(location.pathname);
      window.scrollTo(0, 0);
    });
  }

  handleCheckPasswordExpired = () => {
    const { checkExpiredPassword } = this.props.action;
    checkExpiredPassword(undefined, (response) => {
      this.setState({ passwordExpired: response.data?.password_expired });
    });
  };

  handleProcessConfig = (baseConfig) => {
    /* Generate CSS ==> Cache in Context API
      btnRoot ==> Style color of button
      backgroundRoot => Style of background header
      linkRoot ==> Style color + hover of link
      btnRootOutLine ==> Style color outline of button
    */
    const btnRoot = css({
      backgroundColor: baseConfig.buttonBackgroundColor,
      color: baseConfig.buttonTextColor,
      border: '1px solid ' + baseConfig.buttonBackgroundColor,
      '&:hover': {
        backgroundColor: baseConfig.buttonBackgroundHoverColor,
        color: baseConfig.buttonTextHoverColor
      }
    });
    const backgroundRoot = css({
      backgroundColor: baseConfig.headerBackgroundColor,
      color: baseConfig.headerTextColor
    });
    const linkRoot = css({
      color: baseConfig.headerLinkColor,
      '&:hover': {
        color: baseConfig.headerLinkHoverColor
      },
      '&.active': {
        color: baseConfig.headerLinkHoverColor
      }
    });
    const btnRootOutline = css({
      borderColor: baseConfig.buttonBackgroundColor,
      color: baseConfig.buttonBackgroundColor
    });
    const backgroundLine = css({
      backgroundColor: baseConfig.lineBackgroundColor,
      color: baseConfig.buttonTextHoverColor
    });
    // const liRoot = css({
    //   backgroundColor: baseConfig.mainTextColor,
    //   color: baseConfig.subTextColor1,
    //   "&:hover":{
    //     backgroundColor:
    //   }
    // })

    this.setState(
      {
        config: {
          btnRoot,
          backgroundRoot,
          linkRoot,
          btnRootOutline,
          baseConfig,
          backgroundLine
        }
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  };

  handleGetConfig = () => {
    // this.setState({ isLoading: true });
    const payload = {
      organizationId: AuthStorage.organizationId
    };
    const { getConfig } = this.props.action;
    getConfig(payload, (response) => {
      if (response.status) {
        this.handleProcessConfig(response.data);
        ConfigStorage.value = { config: response.data };
      } else {
        this.handleProcessConfig(defaultConfig);
        ConfigStorage.value = { config: defaultConfig };
      }
    });
  };
  /*
    Get List Language Is Supported
  */
  handleGetLanguage = () => {
    const payload = {};
    const { getLanguage } = this.props.action;
    getLanguage(payload, () => {
      const { language } = this.props.store;
      let currentLanguage = LangStorage.getLang;
      if (isUndefined(currentLanguage) && isEmpty(currentLanguage)) {
        const lang = language.data.find((item) => item.code === 'en-us')
          ? 'en-us'
          : language.data[0].code;

        LangStorage.value = {
          language: lang
        };
        currentLanguage = lang;
      }
      this.handleGetLanguageContent(currentLanguage);
    });
  };
  /* Get Content Page by Language */
  handleGetLanguageContent = (languageCode) => {
    const payload = {
      languageCode
    };
    const { getLanguageContent } = this.props.action;
    getLanguageContent(payload, () => {});
  };
  /* 
  How to use notify ==>
  1. Connect Context
  2. call appContext.notifySuccess("Notify","Example call") */
  notifySuccess = (title, content) => {
    toastr.success(content, title, {
      closeButton: true
    });
  };
  notifyError = (title, content) => {
    toastr.error(content, title, {
      closeButton: true
    });
  };
  notifyWarning = (title, content, options) => {
    toast.warn(content, {
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 1,
      theme: 'light',
      ...options
    });
  };

  handleSetFirstLogin = (isFirstLogin) => {
    this.setState({ firstLogin: isFirstLogin });
  };

  handleSetPasswordExpired = (isExpired) => {
    this.setState({ passwordExpired: isExpired });
  };

  handleSetOldPassword = (password) => {
    this.setState({ cacheOldPassword: password });
  };

  render() {
    const { loadingLanguageContent, loadingLanguage, language, languageContent, loadingModal } =
      this.props.store;
    const { isLoading } = this.state;

    //wait for get language content  and set up config
    if (loadingLanguage || loadingLanguageContent || isLoading) {
      return <LoadingFullScreen />;
    }

    //don't have language content
    if (isEmpty(languageContent) || isEmpty(language)) {
      return (
        <div className="p-5" style={{ minHeight: '100vh' }}>
          <h1 className="display-4">Error</h1>
          <p className="lead text-danger">Have an error. Please try again</p>
          <hr className="my-4" />
          <p className="lead">
            <button
              className="btn btn-primary btn-lg"
              href="#"
              role="button"
              onClick={() => {
                window.location.reload();
              }}>
              Reload Page
            </button>
          </p>
        </div>
      );
    }

    return (
      <AppContext.Provider value={{ appContext: this }}>
        <LoadingModal loading={Boolean(loadingModal.requestIds.length)} />
        <ModalChangePassword />
        <ToastContainerToastify />
        <ToastContainer ref={(ref) => (toastr = ref)} className="toast-top-right" />
        <Navbar handleGetLanguageContent={this.handleGetLanguageContent} />
        <RootRoute />
        <Footer />
      </AppContext.Provider>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wrapper));
