import classNames from 'classnames';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const ControlNavigation = ({
  onBack,
  onNext,
  disabledNext,
  disabledBack,
  centerElement
}) => {
  const { immersive360View } = useSelector(
    (state) => state.contentPage.languageContent.data.data.missionDetailPage
  );

  return (
    <div className="d-flex align-items-center px-3 rounded" style={{ gap: 16 }}>
      <OverlayTrigger
        overlay={
          <Popover style={{ backgroundColor: '#ffffff' }}>
            <Popover.Title>{immersive360View.btnBack}</Popover.Title>
            <Popover.Content>{immersive360View.navigateBackScene}</Popover.Content>
          </Popover>
        }>
        <i
          onClick={onBack}
          aria-hidden="true"
          style={{ color: 'white', fontSize: 24 }}
          className={classNames('cursor-pointer fa fa-arrow-left', {
            disabled: disabledBack
          })}></i>
      </OverlayTrigger>

      {centerElement}

      <OverlayTrigger
        overlay={
          <Popover style={{ backgroundColor: '#ffffff' }}>
            <Popover.Title>{immersive360View.btnNext}</Popover.Title>
            <Popover.Content>{immersive360View.navigateToNextScene}</Popover.Content>
          </Popover>
        }>
        <i
          onClick={onNext}
          aria-hidden="true"
          style={{ color: 'white', fontSize: 24 }}
          className={classNames('cursor-pointer fa fa-arrow-right', {
            disabled: disabledNext
          })}></i>
      </OverlayTrigger>
    </div>
  );
};

ControlNavigation.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  disabledNext: PropTypes.bool.isRequired,
  disabledBack: PropTypes.bool.isRequired
};
