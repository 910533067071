import { useEffect } from 'react';

const useStylePointer = ({ viewer, isAddMode }) => {
  useEffect(() => {
    if (isAddMode) {
      try {
        if (viewer?.container) viewer.container.style.cursor = 'cell';
      } catch (error) {
        console.log('error', error);
      }
    } else {
      try {
        if (viewer?.container) viewer.container.style.cursor = 'move';
      } catch (error) {
        console.log('error', error);
      }
    }
  }, [isAddMode]);
  return <div></div>;
};

export default useStylePointer;
