import isEmpty from 'lodash/isEmpty';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import SyncView from 'features/immersive/SyncView';
import { getAnotherAvailableDate, getInspectionImmersiveViewData } from 'providers/services';
import { appendRequestId, removeRequestId } from 'redux/action/loadingModal';
import { generateRequestId } from 'utils/FetchApi';

const ImmersiveView = () => {
  const dispatch = useDispatch();
  const { inspectionId: missionGuid } = useParams();
  const [immersiveViewData, setImmersiveViewData] = useState([]); // [0,1]
  const [availableDates, setAvailableDates] = useState([]);

  const handleGetImmersiveData = async (missionGuid, index) => {
    const requestId = generateRequestId();
    dispatch(appendRequestId(requestId));
    try {
      const response = await getInspectionImmersiveViewData(missionGuid);
      setImmersiveViewData((prev) => {
        prev[index] = response[0];
        return prev;
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(removeRequestId(requestId));
    }
  };

  const handleGetAvailableDate = async (missionGuid) => {
    const requestId = generateRequestId();
    dispatch(appendRequestId(requestId));
    try {
      const response = await getAnotherAvailableDate(missionGuid);
      if (!response.status && isEmpty(response.inspections)) throw new Error(response.error);
      const availableDates = response.inspections;
      setAvailableDates(availableDates);
      await handleGetImmersiveData(availableDates[0].mission_guid, 0);
      await handleGetImmersiveData(availableDates[0].mission_guid, 1);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(removeRequestId(requestId));
    }
  };

  useEffect(() => {
    if (!missionGuid) return;
    handleGetAvailableDate(missionGuid);
  }, [missionGuid]);

  return (
    <Fragment>
      <div className="header" />
      <div className="container-fluid">
        {!isEmpty(immersiveViewData) && (
          <SyncView
            data={immersiveViewData}
            availableDates={availableDates}
            onChangeDate={handleGetImmersiveData}
          />
        )}
      </div>
    </Fragment>
  );
};

export default ImmersiveView;
